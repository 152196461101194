import dayjs from "dayjs"
import {
  CURRENCY_TYPE_EUR,
  CURRENCY_TYPE_IQD,
  CURRENCY_TYPE_USD,
} from "../bank-accounts"

export const MAP_CURRENCY_TO_LABEL = {
  [CURRENCY_TYPE_IQD]:
    "web_c_analytics_balance_balancgraph_accountlist_iqd_label",
  [CURRENCY_TYPE_USD]:
    "web_c_analytics_balance_balancgraph_accountlist_usd_label",
  [CURRENCY_TYPE_EUR]:
    "web_c_analytics_balance_balancgraph_accountlist_euro_label",
}

export const initialDateRanges = [
  {
    startDate: dayjs().startOf("year").toDate(),
    endDate: new Date(),
    key: "selection",
    initial: true,
  },
]

export const ACCOUNTS_CHART_AREA_COLORS = [
  "#5AC076",
  "#508FF0",
  "#cf259c",
  "#9b50f0",
  "#d550f0",
  "#f050b0",
  "#f05070",
  "#f09550",
  "#3f25cf",
  "#25cfcf",
]

export const DATE_RANGE_ID = "DATE_RANGE_ID"
export const CURRENT_ACCOUNT_ID = "CURRENT_ACCOUNT_ID"
export const CURRENT_SUBACCOUNT_ID = "CURRENT_SUBACCOUNT_ID"
export const CURRENT_TRANSACTION_TYPE_ID = "CURRENT_TRANSACTION_TYPE_ID"
export const CURRENT_OPERATION_STATUS_GROUP_ID =
  "CURRENT_OPERATION_STATUS_GROUP_ID"

export const SIX_MONTH_THRESHOLD = 6 * 31
export const TWENTY_FIVE_YEAR_THRESHOLD = 365 * 25
export const TEN_YEAR_THRESHOLD = 365 * 10
export const FOUR_YEAR_THRESHOLD = 365 * 4

export const BALANCE_BREADCRUMB_DATA = {
  title: "web_c_analytics_balance_title",
  breadcrumbsList: [
    { text: "web_c_menu_analytics_label" },
    { text: "web_c_analytics_submenu_balance_label" },
  ],
}
