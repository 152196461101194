import AxiosInstance from "../axios"

/**
 * @typedef BankTransferEnumeratedType
 * @property {string} value
 * @property {string} translationKey
 */

/**
 * @typedef {   "PAYMENT" | "ONLINE_PAYMENT" | "P2P" | "DEPOSIT" |
 *              "WITHDRAWAL" | "DOMESTIC_BANK_TRANSFER" | "INTERNATIONAL_BANK_TRANSFER" |
 *              "SERVICESTORE_PAYMENT" | "INVITE_TRANSFER" | "INVITE_TRANSFER_FORWARD" |
 *              "INVITE_TRANSFER_RETURN" | "CURRENCY_EXCHANGE" | "MONEY_BOX_TRANSFER" |
 *              "TERMINAL_TRANSFER" | "REMAINING_FUNDS_TRANSFER" | "DELIVERY_DEPOSIT_FEE" |
 *              "DELIVERY_WITHDRAWAL_FEE" | "CARD_ORDER_FEE" | "CARD_RECURRING_FEE" |
 *              "PRO_MEMBERSHIP_RECURRING_FEE" | "LOAN_REPAYMENT" | "BUY_NOW_PAY_LATER_REPAYMENT" |
 *              "FAMILY_ACCOUNT_TRANSFER" | "SALARY_TRANSFER" | "CARD_ORDER_FEE_REFUND"
 *          } TransactionType
 */

/**
 * @typedef InitiateTransactionResponse
 * @property {string} transactionId
 * @property {string} readableId
 * @property {number} expiresAt
 *
 * @param {import("axios").AxiosRequestConfig} config
 *
 * @returns {Promise<import("axios").AxiosResponse<InitiateTransactionResponse>>}
 */
export const initiateTransaction = (transferInformations, config) =>
  AxiosInstance.post("/v2/transactions", transferInformations, config)

/**
 * @typedef TransactionDetails
 * @property {string} id
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} monetaryValue
 * @property {string} sourceAccountId
 * @property {string} targetAccountId
 * @property {string} [note]
 * @property {string} [gifId]
 * @property {TransactionType} type
 * @property {createdAt} number
 * @property {object} bankTransfer
 * @property {string} bankTransfer.iban
 * @property {string} bankTransfer.bicCode
 * @property {string} bankTransfer.country
 * @property {string} bankTransfer.bankName
 * @property {string} bankTransfer.receiverName
 * @property {BankTransferEnumeratedType} bankTransfer.purpose
 * @property {object[]} [attachments]
 * @property {string} attachments.id
 * @property {string} attachments.fileName
 * @property {string} attachments.fileType
 * @property {number} attachments.size
 *
 * @param {string} transactionId
 * @returns {Promise<import("axios").AxiosResponse<TransactionDetails>>}
 */
export const getTransactionDetails = (transactionId) =>
  AxiosInstance.get(`/v2/transactions/${transactionId}`)

/**
 * @typedef BankTransferEnumeratedTypes
 * @property {BankTransferEnumeratedType[]} purposes
 * @property {BankTransferEnumeratedType[]} sessionPeriods
 *
 *
 * @returns {Promise<import("axios").AxiosResponse<BankTransferEnumeratedTypes>>}
 */
export const getBankTransferEnumeratedTypes = () =>
  AxiosInstance.get("/v1/bank-transfers/enumerated-types")

/**
 * @param {string} transactionid
 * @param {string} sourceAccountId
 *
 * @returns {Promise<import("axios").AxiosResponse<{sourceAccountId: string}>>}
 */
export const confirmTransaction = (transactionid, sourceAccountId) =>
  AxiosInstance.patch(`/v2/transactions/${transactionid}`, sourceAccountId)

/**
 * @param {string} transactionId
 * @param {import("axios").AxiosRequestConfig} config
 */
export const authorizeConfirmedTransaction = (transactionId, config) =>
  AxiosInstance.post(
    `/v4/transactions/${transactionId}/authorize`,
    null,
    config
  )

/**
 * @typedef TransactionFeeCalculation
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} baseAmount
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} calculatedFee
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} transactionAmount
 *
 * @param {string} transactionId
 *
 * @returns {Promise<import("axios").AxiosResponse<TransactionFeeCalculation>>}
 */
export const initiatedTransactionFeeCalculation = (transactionId) =>
  AxiosInstance.post(`/v1/transactions/${transactionId}/fees`)
