import { forwardRef } from "react"
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useMergeRefs,
} from "@floating-ui/react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useDialogContext } from "./context/dialogContext"

/**
 * @type {ForwardRefExoticComponent<RefAttributes<{className: string}>>}
 */
const DialogContent = forwardRef((props, propRef) => {
  const { context: floatingContext, ...context } = useDialogContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])
  const shouldReturnFocus = context.refs.reference.current !== null

  return (
    <FloatingPortal data-testid="dialog-portal">
      {context.open && (
        <FloatingOverlay
          className="dialog__overlay"
          data-testid="dialog-overlay"
          lockScroll
        >
          <FloatingFocusManager
            context={floatingContext}
            returnFocus={shouldReturnFocus}
          >
            <div
              ref={ref}
              data-testid="dialog-content"
              aria-labelledby={context.labelId}
              aria-describedby={context.descriptionId}
              {...context.getFloatingProps(props)}
              className={classNames("dialog__content", props.className)}
            >
              {props.children}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  )
})

DialogContent.displayName = "DialogContent"

DialogContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

DialogContent.defaultProps = { className: undefined }

export default DialogContent
