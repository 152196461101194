import PropTypes from "prop-types"
import { MIME_TYPES } from "../../shared/constants/general"
import { Dialog, DialogContent } from "../dialog/Dialog"

const DocumentView = ({ isOpen, setIsOpen, onClose, document }) => {
  const handleOnOpenChange = (isDialogOpen) => {
    if (!isDialogOpen) {
      setIsOpen(false)
      onClose?.()
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOnOpenChange}>
      <DialogContent className="document-view">
        <div className="document-view__body">
          {document?.type === MIME_TYPES.applicationPdf && (
            <iframe
              src={`${document?.url}#toolbar=0&navpanes=0`}
              title={document?.name}
              className="document-view__body-pdf"
              data-testid="document-view-pdf"
            />
          )}
          {document?.type !== MIME_TYPES.applicationPdf && (
            <img
              src={document?.url}
              data-testid="document-view-image"
              alt={document?.name}
              className="document-view__body-image"
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

DocumentView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  document: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.oneOf(Object.values(MIME_TYPES)),
  }),
}

DocumentView.defaultProps = {
  onClose: undefined,
  document: undefined,
}

export default DocumentView
