export const MAP_LATIN_NUM_TO_ARABIC_NUM = {
  "\u0030": "\u0660",
  "\u0031": "\u0661",
  "\u0032": "\u0662",
  "\u0033": "\u0663",
  "\u0034": "\u0664",
  "\u0035": "\u0665",
  "\u0036": "\u0666",
  "\u0037": "\u0667",
  "\u0038": "\u0668",
  "\u0039": "\u0669",
}

export const MAP_ARABIC_NUM_TO_LATIN_NUM = {
  "\u0660": "\u0030",
  "\u0661": "\u0031",
  "\u0662": "\u0032",
  "\u0663": "\u0033",
  "\u0664": "\u0034",
  "\u0665": "\u0035",
  "\u0666": "\u0036",
  "\u0667": "\u0037",
  "\u0668": "\u0038",
  "\u0669": "\u0039",
}

export const THOUSANDS_MATCHER_REGEX = /\B(?=(\d{3})+(?!\d))/g
export const ALPHABETICAL_NAME_VALIDATOR_REGEX =
  /^([A-Za-z\u0600-\u06FF]+( )?)+$/
export const NAME_VALIDATOR_REGEX = /^([\SA-Za-z0-9\u0600-\u06FF]+( )?)+$/
export const NUMBERICAL_ONLY_REGEX = /^[0-9]*$/
export const ONLY_NUMBERS_REGEX = /^[0-9]+$/
export const PHONE_NUMBER_REGEX = /^7\d{9}$/
export const ONE_DIGIT_REGEX = /^\d$/
export const MULTIPLE_DIGITS_REGEX = /^\d+$/
export const BIC_CODE_REGEX = /^[A-Z]{4}IQ[A-Z0-9]{2}([A-Z0-9]{3})$/
export const ALPHANUMERIC_REGEX =
  /^(?!.*[،؛ـ؟])(?!.* {2})[a-zA-Z0-9\u0600-\u06FF]+(?: [a-zA-Z0-9\u0600-\u06FF]+)*$/

export const getCapitalLettersRegex = () => /[A-Z]/g
export const getSmallLettersRegex = () => /[a-z]/g
export const getNumbersRegex = () => /[0-9]/g
export const getSpecialCharactersRegex = () => /[!@#$%^&*=+=_~]/g

export const PASSWORD_STRENGTH_WEAK = "WEAK"
export const PASSWORD_STRENGTH_AVERAGE = "AVERAGE"
export const PASSWORD_STRENGTH_STRONG = "STRONG"

export const PASSWORD_ERROR_SHORT_PASSWORD = "SHORT_PASSWORD"
export const PASSWORD_ERROR_MISSING_UPPERCASE = "MISSING_UPPERCASE"
export const PASSWORD_ERROR_MISSING_LOWERCASE = "MISSING_LOWERCASE"
export const PASSWORD_ERROR_MISSING_SPECIAL_CHAR = "MISSING_SPECIAL_CHAR"

export const DEFAULT_TABLE_PARAMS = {
  page: 0,
  size: 25,
}

export const TABLE_SIZE = {
  transactions: 10,
}

/**
 * @typedef {object} initialDate
 * @property {Date} date
 * @property {boolean} initial
 */
export const initialDate = {
  date: new Date(),
  initial: true,
}

export const IRAQ_DAILING_CODE = "+964"

// TODO define a reusable object with a general code and message
export const GENERAL_ERROR = "GENERAL_ERROR"

export const DEFAULT_INFINITE_PARAMS = {
  page: 0,
  size: 500 ** 3,
}

export const VALID_FILE_NAME_REGEX = /^[\w\s,+!@#$^&*()[\]{}-]+\.[A-Za-z]{2,}$/

export const NOT_FOUND = "NOT_FOUND"

export const ACTIVE = "ACTIVE"
export const INACTIVE = "INACTIVE"
export const OTP_PENDING = "OTP_PENDING"

export const FILE_TYPES = {
  jpeg: "JPEG",
  jpg: "JPG",
  png: "PNG",
  pdf: "PDF",
  xlsx: "XLSX",
  csv: "CSV",
}

export const MIME_TYPES = {
  imageJpeg: "image/jpeg",
  imageJpg: "image/jpg",
  imagePng: "image/png",
  imageJng: "image/jng",
  applicationPdf: "application/pdf",
  textCSV: "text/csv",
  excelSheet:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export const MAP_MIME_TYPE_TO_EXTENSIONS = {
  [MIME_TYPES.imageJpeg]: FILE_TYPES.jpeg,
  [MIME_TYPES.imageJpg]: FILE_TYPES.jpg,
  [MIME_TYPES.imagePng]: FILE_TYPES.png,
  [MIME_TYPES.applicationPdf]: FILE_TYPES.pdf,
  [MIME_TYPES.excelSheet]: FILE_TYPES.xlsx,
  [MIME_TYPES.textCSV]: FILE_TYPES.csv,
}
