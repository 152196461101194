import PropTypes from "prop-types"

import DialogContent from "./DialogContent"
import { DialogContext } from "./context/dialogContext"
import DialogTrigger from "./DialogTrigger"
import { useDialog } from "../../shared/hooks/useDialog"

export { DialogTrigger, DialogContent }

/**
 *
 * @param {object} props
 * @param {function} props.onOpenChange
 * @param {boolean} [props.initialOpen=false]
 * @param {boolean} props.open
 * @param {React.ReactNode} props.children
 */
export function Dialog({ onOpenChange, initialOpen, children, open }) {
  const dialog = useDialog({
    onOpenChange,
    initialOpen,
    open,
  })
  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  )
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  onOpenChange: PropTypes.func,
  initialOpen: PropTypes.bool,
  open: PropTypes.bool,
}

Dialog.defaultProps = {
  onOpenChange: undefined,
  initialOpen: undefined,
  open: undefined,
}
