import { useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { fetchDocument } from "../../api/endpoints"
import { MAP_MIME_TYPE_TO_EXTENSIONS } from "../../shared/constants/general"
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
} from "../../shared/constants/toast"
import Toast from "../toast/Toast"
import DocumentView from "../documentView/DocumentView"
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg"
import { ReactComponent as EyeIcon } from "../../assets/icons/opened-eye.svg"
import { downloadFile } from "../../lib/downloadFile"

const AttachmentSection = ({ header, attachments }) => {
  const { t } = useTranslation()
  const [isDocumentViewOpen, setIsDocumentViewOpen] = useState(false)
  const [document, setDocument] = useState()
  const fetchedDocuments = useRef([])

  const fetchDocumentData = useCallback(
    async (attachment) => {
      const previouslyFetchedDocuments = fetchedDocuments.current.find(
        ({ url: fetchedUrl }) => fetchedUrl === attachment.attachmentUrl
      )

      if (previouslyFetchedDocuments) {
        return {
          blobUrl: previouslyFetchedDocuments.blobUrl,
          blob: previouslyFetchedDocuments.blob,
        }
      }

      try {
        const { data: blob } = await fetchDocument(attachment.attachmentUrl)
        const newlyFetchedDocument = {
          blob,
          url: attachment.attachmentUrl,
          blobUrl: URL.createObjectURL(blob),
        }

        fetchedDocuments.current.push(newlyFetchedDocument)

        return newlyFetchedDocument
      } catch (error) {
        toast(
          <Toast
            text={t("bac_general_doc_loading_error_alert")}
            type={TOAST_TYPE_ERROR}
          />
        )
        return null
      }
    },
    [t]
  )

  const handleViewClicked = useCallback(
    (attachment) => async () => {
      if (document?.id === attachment.id && isDocumentViewOpen) return

      const result = await fetchDocumentData(attachment)

      if (!result) return
      const { blobUrl, blob } = result

      setIsDocumentViewOpen(true)
      setDocument({
        url: blobUrl,
        name: attachment.filename,
        type: blob.type,
      })
    },
    [document?.id, fetchDocumentData, isDocumentViewOpen]
  )

  const handleDownloadClicked = useCallback(
    (attachment) => async () => {
      const result = await fetchDocumentData(attachment)

      if (!result) return
      const { blob } = result

      const fileExtension =
        MAP_MIME_TYPE_TO_EXTENSIONS[blob.type]?.toLowerCase()
      const nameWithoutSpaces = attachment.filename.replace(/\s/g, "-")

      downloadFile(blob, nameWithoutSpaces.concat(".", fileExtension))
      toast(
        <Toast
          text={t("bac_general_doc_download_success_alert")}
          type={TOAST_TYPE_SUCCESS}
        />
      )
    },
    [fetchDocumentData, t]
  )

  useEffect(
    () => () => {
      if (fetchedDocuments.current.length > 0) {
        fetchedDocuments.current.forEach((fetchedDocument) => {
          URL.revokeObjectURL(fetchedDocument.blobUrl)
        })
      }
    },
    []
  )

  return (
    <section className="details-sidebar-body__section">
      {header && (
        <div className="details-sidebar-body__section-header">{header}</div>
      )}
      <DocumentView
        isOpen={isDocumentViewOpen}
        setIsOpen={setIsDocumentViewOpen}
        onClose={() => setDocument(undefined)}
        document={document}
      />
      {attachments.map((attachment) => (
        <div
          key={attachment.id}
          className="details-sidebar-body__attachment-section-row"
          data-testid="attachment-section-row"
        >
          <div
            className="details-sidebar-body__attachment-section-row-name"
            title={attachment.filename}
          >
            {attachment.filename}
          </div>
          <div className="details-sidebar-body__attachment-section-row-value">
            <button
              type="button"
              data-testid="attachment-section-view-button"
              onClick={handleViewClicked(attachment)}
              className="button button--outline-secondary attachment-section-action-button"
            >
              <EyeIcon width={16} height={16} />
            </button>
            <button
              type="button"
              data-testid="attachment-section-download-button"
              onClick={handleDownloadClicked(attachment)}
              className="button button--outline-secondary attachment-section-action-button"
            >
              <DownloadIcon width={16} height={16} />
            </button>
          </div>
        </div>
      ))}
    </section>
  )
}

AttachmentSection.propTypes = {
  header: PropTypes.string,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      filename: PropTypes.string,
      attachmentUrl: PropTypes.string,
    })
  ).isRequired,
}

AttachmentSection.defaultProps = {
  header: undefined,
}

export default AttachmentSection
