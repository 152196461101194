import classNames from "classnames"
import PropTypes from "prop-types"
import { ReactComponent as TimesIcon } from "../../assets/icons/times.svg"
import Section from "./Section"
import SectionExpanded from "./SectionExpanded"
import UserInfo from "./UserInfo"
import SectionLink from "./SectionLink"
import SectionRow from "./SectionRow"
import SectionTotal from "./SectionTotal"
import AttachmentSection from "./AttachmentSection"

const DetailsSidebar = ({
  sidebarTestId,
  closeBtnTestId,
  title,
  rootClassName,
  children,
  onClosed,
  shouldAppear,
}) => {
  const onClickHandler = (e) => {
    e.stopPropagation()
    if (onClosed) onClosed()
  }

  return (
    <aside
      className={classNames("details-sidebar", {
        "details-sidebar--hidden": !shouldAppear,
      })}
      data-testid={sidebarTestId || "details-sidebar"}
      data-shown={shouldAppear}
    >
      <div
        className={classNames("details-sidebar__backdrop", {
          "details-sidebar__backdrop--hidden": !shouldAppear,
        })}
        role="presentation"
        data-testid="details-sidebar-backdrop"
        onClick={onClickHandler}
      />
      <div
        className={classNames(rootClassName, "details-sidebar__root", {
          "details-sidebar__root--hidden": !shouldAppear,
        })}
      >
        <header className="details-sidebar__header">
          <h3 className="details-sidebar__title">{title}</h3>
          <button
            className="button button--iconed details-sidebar__dismiss-button"
            type="button"
            data-testid={closeBtnTestId || "close-details-sidebar-button"}
            onClick={onClickHandler}
          >
            <TimesIcon width={24} height={24} />
          </button>
        </header>
        <div className="details-sidebar__separator" />
        <div className="details-sidebar__body">{children}</div>
      </div>
    </aside>
  )
}

DetailsSidebar.Section = Section
DetailsSidebar.AttachmentSection = AttachmentSection
DetailsSidebar.UserInfo = UserInfo
DetailsSidebar.SectionLink = SectionLink
DetailsSidebar.SectionRow = SectionRow
DetailsSidebar.SectionTotal = SectionTotal
DetailsSidebar.SectionExpanded = SectionExpanded

DetailsSidebar.propTypes = {
  sidebarTestId: PropTypes.string,
  closeBtnTestId: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClosed: PropTypes.func,
  shouldAppear: PropTypes.bool,
  rootClassName: PropTypes.string,
}

DetailsSidebar.defaultProps = {
  sidebarTestId: undefined,
  closeBtnTestId: undefined,
  onClosed: undefined,
  shouldAppear: false,
  rootClassName: undefined,
}

export default DetailsSidebar
