import { createContext, useContext } from "react"

export const DialogContext = createContext(null)

export const useDialogContext = () => {
  const context = useContext(DialogContext)

  if (context === null) {
    throw new Error("Dialog components must be wrapped in <Dialog />")
  }

  return context
}
